"use strict";

const RainforestPort = {
  add: function (app, rainforestEnv) {
    const insertScriptTag = (rainforestEnv) => {
      const paymentScript = document.createElement("script");

      if (rainforestEnv === "production") {
        paymentScript.src = "https://static.rainforestpay.com/payment.js";
      } else {
        paymentScript.src = "https://static.rainforestpay.com/sandbox.payment.js";
      }

      document.body.appendChild(paymentScript);
    };

    insertScriptTag(rainforestEnv);

    app.ports.rainforestSubmitPaymentMethod.subscribe(function () {
      const component = document.getElementsByTagName("rainforest-payment")[0];

      if (!component) {
        return;
      }

      component.submit();
    });
  },
};

module.exports = RainforestPort;
