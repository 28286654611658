const setup = function () {
  (function (i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, "script", "//www.google-analytics.com/analytics.js", "ga");
};

const pageView = function (path) {
  window.ga("send", "pageview", {
    page: path,
  });
};

const GoogleAnalyticsPort = {
  add: function (app) {
    setup();

    let enabled = false;

    if (app.ports.googleAnalyticsPortCreate) {
      app.ports.googleAnalyticsPortCreate.subscribe(function (args) {
        let googleAnalyticsTrackingId = args[0];
        let path = args[1];

        enabled = true;
        window.ga("create", googleAnalyticsTrackingId, "auto");
        pageView(path);
      });
    }

    if (app.ports.googleAnalyticsPortPageView) {
      app.ports.googleAnalyticsPortPageView.subscribe(function (path) {
        if (!enabled) {
          return;
        }

        pageView(path);
      });
    }

    if (app.ports.googleAnalyticsPortEvent) {
      app.ports.googleAnalyticsPortEvent.subscribe(function (event) {
        if (!enabled) {
          return;
        }

        window.ga("send", event);
      });
    }
  },
};

module.exports = GoogleAnalyticsPort;
