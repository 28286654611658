const NavigationPort = {
  add: function (app) {
    // Inform app of browser navigation (the BACK and FORWARD buttons)
    window.addEventListener("popstate", function () {
      app.ports.onUrlChange.send(location.href);
    });

    // Change the URL upon request, inform app of the change.
    app.ports.pushUrl.subscribe(function (url) {
      history.pushState({}, "", url);
      app.ports.onUrlChange.send(location.href);
    });

    // Intercept link clicks to change the location without refreshing the page
    document.addEventListener("click", function (event) {
      const { target } = event;
      if (event.ctrlKey || event.metaKey) {
        return;
      }
      const isLocalHref = (targetA) => {
        return targetA.host == location.host;
      };
      const linkElement = target.localName == "a" ? target : target.closest("a");
      if (linkElement && linkElement.href && isLocalHref(linkElement)) {
        event.preventDefault();
        history.pushState({}, "", linkElement.href);
        app.ports.onUrlChange.send(linkElement.href);
      }
    });

    if (app.ports.openInNewWindow) {
      app.ports.openInNewWindow.subscribe((url) => {
        const newPage = window.open("http://hint.com", "_blank");
        newPage.location = url;
      });
    }

    if (app.ports.replaceUrl) {
      app.ports.replaceUrl.subscribe((url) => {
        history.replaceState({}, "", url);
      });
    }
  },
};

module.exports = NavigationPort;
