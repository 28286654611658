import { storageFactory } from "storage-factory";

const storage = storageFactory(() => localStorage);

const IdleTimePort = {
  add: (app) => {
    const storageKey = "lastActivityAt";
    const oneMinMs = 60000;
    const halfMinMs = 30000;
    const tenMinThreshold = 10;
    const idleThresholdMs = tenMinThreshold * oneMinMs;

    function throttle(func, timeFrame) {
      var lastTime = 0;
      return function () {
        var now = Date.now();
        if (now - lastTime >= timeFrame) {
          func();
          lastTime = now;
        }
      };
    }

    function checkIdleTimeAndlogout() {
      const lastActivityAt = parseInt(storage.getItem(storageKey));
      const now = Date.now();

      if (lastActivityAt && now - lastActivityAt > idleThresholdMs) {
        app.ports.idleUser.send(true);
      }
    }

    const trackLastActivity = () => {
      storage.setItem(storageKey, Date.now());
    };

    setInterval(checkIdleTimeAndlogout, oneMinMs);
    window.addEventListener("mousemove", throttle(trackLastActivity, halfMinMs));
    window.addEventListener("keypress", throttle(trackLastActivity, halfMinMs));
  },
};

module.exports = IdleTimePort;
