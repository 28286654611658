const WindowPort = {
  add: function (app) {
    const isInView = function (element) {
      if (!element) return false;

      const rect = element.getBoundingClientRect();
      return rect.top >= 0 && rect.bottom <= window.innerHeight && rect.left >= 0 && rect.right <= window.innerWidth;
    };

    app.ports.scrollTopAndIntoView.subscribe(function (elementId) {
      var element = document.getElementById(elementId);
      if (!element) return;

      // don't scroll if already visible
      if (isInView(element)) return;

      var scrollTimeout;
      var handleScroll = function () {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function () {
          removeEventListener("scroll", handleScroll, true);
          setTimeout(function () {
            window.scroll({ top: 0, behavior: "smooth" });
          }, 250);
        }, 100);
      };

      addEventListener("scroll", handleScroll, true);

      element.scrollIntoView({ behavior: "smooth", block: "end" });
    });

    app.ports.scrollIdIntoView.subscribe(function (elementId) {
      var element = document.getElementById(elementId);
      if (!element) return;

      // don't scroll if already visible
      if (isInView(element)) return;

      element.scrollIntoView({ behavior: "smooth" });
    });

    app.ports.scrollIdToBottom.subscribe(function (elementId) {
      var element = document.getElementById(elementId);
      if (!element) return;

      element.scrollIntoView({ behavior: "smooth", block: "end" });
    });

    var scrollTimer = null;
    var lastScrollFireTime = 0;
    var minScrollTime = 100;

    window.addEventListener("scroll", () => {
      if (!scrollTimer) {
        var now = new Date().getTime();
        if (now - lastScrollFireTime > minScrollTime) {
          app.ports.onWindowScroll.send({
            x: window.scrollX,
            y: window.scrollY,
          });
          lastScrollFireTime = now;
        }
        scrollTimer = setTimeout(function () {
          scrollTimer = null;
          lastScrollFireTime = new Date().getTime();
          app.ports.onWindowScroll.send({
            x: window.scrollX,
            y: window.scrollY,
          });
        }, minScrollTime);
      }
    });

    app.ports.printWindow.subscribe(function () {
      window.print();
    });
  },
};

module.exports = WindowPort;
